import React, { useMemo, type ReactNode } from "react"
import clsx from "clsx"
import Button from "@material-ui/core/Button"

import Container from "@ecom/ui/components/Container"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { TooltipQuestion } from "./Tooltip/Tooltip"
import { GradientBg } from "../GradientBg"

import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { items } from "./helpers"

import * as styles from "./moreBenefitsRedisign.module.scss"

type MoreBenefitsRedesignProps = {
  additionalEventInDataLayer?: boolean
  bottomBlock?: boolean
  variant?: "itemsMedia" | "refin" | "inviteFriendItems" | "btsAuto"
  title?: ReactNode
  subtitle?: ReactNode
  hasSubtitle?: boolean
  orderNum?: string
}

export const MoreBenefitsRedisign = ({
  additionalEventInDataLayer = false,
  bottomBlock = true,
  variant = "itemsMedia",
  title = "Больше выгоды",
  subtitle = "С подпиской «Халва.Десятка»",
  hasSubtitle = true,
  orderNum,
}: MoreBenefitsRedesignProps) => {
  const handleClick = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayer({
        event: "buttonClick",
        name: "bottom_cta_desyatka",
      })
    }
    scrollToPersonalForm()
  }

  const itemsList = useMemo(
    () =>
      items[variant].map(({ Img, alt, title: itemTitle, tooltipHint, hintClassName }, i) => (
        <GradientBg
          key={i}
          className={clsx(styles.item, styles[`item${i + 1}`], variant && styles[variant])}
        >
          <div className={clsx(styles.itemText, variant && styles[variant])}>
            <p>{itemTitle}</p>
            {tooltipHint && <TooltipQuestion text={tooltipHint} hintClassName={hintClassName} />}
          </div>
          <Img alt={alt} className={styles.img} />
        </GradientBg>
      )),
    [variant]
  )

  return (
    <section className={styles.section} id="moreBenefits" data-exclude={orderNum}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.textBlock}>
            <div className={styles.title}>{title}</div>
            {hasSubtitle && <div className={styles.desc}>{subtitle}</div>}
          </div>
          <div className={clsx(styles.wrap, variant && styles[variant])}>{itemsList}</div>
        </div>
        {bottomBlock && (
          <div className={styles.kameleoonBlock}>
            <div className={styles.kameleoonTitle}>
              Оформите Халву и получите <br /> <b> месяц подписки в подарок </b>
            </div>
            <Button className={styles.kameleoonBtn} onClick={handleClick}>
              Оформить карту
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
